<template>
  <!-- main-area -->
  <main>
    <!-- login-area -->
    <section>
      <div class="row m-0" style="height: 100vh">
        <div class="col-lg-6" style="padding-left: 100px; padding-top: 50px">
          <div class="logo mb-5">
            <a href="/"><img :src="logo" alt="logo" /></a>
          </div>
        </div>
        <div class="col-lg-6" style="background-color: #d1eed2"></div>
        <div
          class="col-lg-6 form-col"
          style="padding-left: 100px; padding-right: 100px"
        >
          <h3 class="modal-content-h3">{{ forgotLabel.hello }}!</h3>
          <p class="modal-content-p">{{ forgotLabel.headerTitle }}</p>
          <form
            @submit.prevent="handleLogin()"
            novalidate
            :class="submitted ? 'was-validated' : ''"
          >
            <div class="form-group m-b-2">
              <label for="inputEmail">{{ forgotLabel.email }}</label>
              <input
                type="email"
                class="form-control"
                id="inputEmail"
                :placeholder="forgotLabel.email"
                name="email"
                v-model="formData.customerEmail"
                required
              />
              <div class="invalid-feedback">Email is required.</div>
            </div>

            <button
              class="modal-content-button mt-4"
              @click="submitted = true"
              :disabled="loading"
            >
              {{ forgotLabel.reset }}
            </button>
            <button class="modal-content-button mt-3" @click="handleBack()">
              {{ forgotLabel.backbtn }}
            </button>
          </form>
          <div class="row mt-5 mx-0" style="width: 400px">
            <!-- <div class="col-md-3 p-0 mt-2">
              <div class="login-line"></div>
            </div>
            <div class="col-md-6 text-center p-0">
              <p class="p-0 m-0" style="font-size: 12px; color: #8a9099">
                Or SIGN IN with
              </p>
            </div>
            <div class="col-md-3 p-0">
              <div class="login-line mt-2"></div>
            </div> -->
          </div>
          <!-- <button class="social-button my-4" @click="googleAuth">
            <img :src="google" /> {{ " " }}Sign in with Google
          </button>
          <button class="social-button my-4" @click="loginWithFacebook">
            <img :src="facebook" /> {{ " " }}Sign in with Facebook
          </button> -->
        </div>
        <div
          class="col-lg-6 d-none d-lg-block"
          style="
            background-color: #d1eed2;
            padding-left: 100px;
            padding-right: 100px;
          "
        >
          <div
            class="slider-img d-flex justify-content-center"
            data-animation="fadeInRight"
            data-delay=".6s"
          >
            <img style="width: 462px" :src="bookingSummary" alt="" />
          </div>
          <p style="font-size: 24px; color: #000; text-align: center">
            {{ forgotLabel.left_side_headerTitle }}
          </p>
          <p
            style="
              font-size: 16px;
              line-height: 18px;
              color: #595f69;
              text-align: center;
            "
          >
            {{ forgotLabel.description }}
          </p>
        </div>
      </div>
    </section>
    <!-- login-area-end -->
  </main>
</template>

<script>
import logo from "../static/img/logo/logo1.png";
import google from "../assets/Icons/google-icon.png";
import facebook from "../assets/Icons/facebook.png";
import bookingSummary from "../static/img/app/orderSummary 1.png";
import ForgotPassword from "../models/ForgotPassword";
import AuthenticationService from "../services/UserService";
import vuex from "vuex";
import VueSweetalert2 from "sweetalert2";
import VFacebookLogin from "vue-facebook-login-component-next";

export default {
  name: "ForgotPassword",
  components: { VFacebookLogin },
  data: function () {
    return {
      logo,
      bookingSummary,
      formData: new ForgotPassword(),
      loading: false,
      submitted: false,
      errorMessage: "",
      facebook,
      google,
      forgotLabel: this.$store.getters.getForgotDetails,
    };
  },
  computed: {
    ...vuex.mapGetters(["currentUser"]),
  },
  mounted() {
    if (this.currentUser?.customerEmail) {
      this.$router.push("/");
    }

    window.fbAsyncInit = function () {
      FB.init({
        appId: "1963849650420861",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0",
      });
    };
  },
  methods: {
    ...vuex.mapActions(["updateUser"]),

    handleBack() {
      this.$router.push("/login");
    },
    loginWithFacebook() {
      FB.login(
        function (response) {
          if (response.status === "connected") {
            FB.api(
              "/me",
              { fields: "name, email, picture, token_for_business" },
              function (response) {
                const userDetails = {
                  customerID: 0,
                  portalID: 0,
                  userName: response.name,
                  firstName: "",
                  lastName: "",
                  password: response.token_for_business,
                  customerEmail: response.email,
                  customerNumber: "string",
                  provider: 2,
                };

                this.formData.email = response.email;
                this.formData.password = response.token_for_business;
                AuthenticationService.login(userDetails)
                  .then((responseData) => {
                    if (responseData.data === "Success") {
                      this.updateUser(JSON.stringify(this.formData));
                      this.$router.push("/");
                    } else if (responseData.data === "Password not correct!") {
                      this.errorMessage = "Invalid Password";
                    } else {
                      VueSweetalert2.fire({
                        toast: true,
                        position: "bottom-end",
                        showConfirmButton: false,
                        timer: 3000,
                        title: "Something went wrong! Please try again.",
                        icon: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    this.errorMessage = error.response.data.message;
                  });
              }
            );
          }
        },
        { scope: "email, public_profile" }
      );
    },
    handleLogin() {
      if (!this.formData.customerEmail) {
        return;
      }
      this.loading = true;
      AuthenticationService.forgetPassword(this.formData.customerEmail)
        .then((response) => {
          if (response.data) {
            VueSweetalert2.fire({
              toast: false,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
              title: "We have sent you a password reset link to your mail.",
              icon: "success",
            });

            setTimeout(() => {
              this.$router.push("/login");
            }, 3000);
          } else {
            VueSweetalert2.fire({
              toast: false,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
              title: response.data,
              icon: "error",
            });

            this.errorMessage = response.data;
          }
        })
        .catch((err) => {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: err.response.data.errors[0].message,
            icon: "error",
          });
          // this.errorMessage = "Unexpected error occurred";
        })
        .then(() => {
          this.loading = false;
        });
    },

    googleAuth() {
      let gapi = window.gapi;
      let clientId =
        "75995858762-i7k3mcuri3mo1nvhvajmne2su6fu7gqq.apps.googleusercontent.com";
      let apiKey = "AIzaSyCRIWn-RmBNfXbk2qvX1m5ekZTZiLHG-kM";
      let descoveryDocs = [
        "https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest",
      ];
      let scope = "https://www.googleapis.com/auth/userinfo.profile";

      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: apiKey,
            clientId: clientId,
            discoveryDocs: descoveryDocs,
            scope: scope,
          })
          .then(() => {
            if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
              this.googleUserProfile = gapi.auth2
                .getAuthInstance()
                .currentUser.get();
              this.loginApiCall(this.googleUserProfile);
            } else {
              gapi.auth2
                .getAuthInstance()
                .signIn()
                .then(() => {
                  this.googleUserProfile = gapi.auth2
                    .getAuthInstance()
                    .currentUser.get();
                  this.loginApiCall(this.googleUserProfile);
                })
                .catch((error) => {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: `'Google Authentication Failed' ${error}`,
                    icon: "error",
                  });
                });
            }
          });
      });
    },

    loginApiCall(data) {
      const signUpData = JSON.parse(JSON.stringify(data)).su;
      if (signUpData) {
        let userDetails = {
          customerID: 0,
          portalID: 0,
          userName: signUpData.qf,
          firstName: signUpData.FX,
          lastName: signUpData.UV,
          password: signUpData.pW,
          customerEmail: signUpData.ev,
          customerNumber: "string",
          provider: 2,
        };
        this.formData.email = signUpData.ev;
        this.formData.password = signUpData.pW;
        AuthenticationService.userRegistration(userDetails)
          .then((response) => {
            if (response.data === "Success") {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: `'Google Authentication Success'`,
                icon: "success",
              });
              this.updateUser(JSON.stringify(this.formData));
              this.$router.push("/");
            } else if (response.data === "Password not correct!") {
              this.errorMessage = "Invalid Passowrd";
            } else {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: "Something went wrong! Please try again.",
                icon: "error",
              });
            }
            if (response.data === "") {
            }
          })
          .catch((error) => {});
      }
    },
  },
};
</script>

<style scoped>
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
.button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: #2b96cc;
  text-decoration: none;
}
a,
button {
  color: #1696e7;
  outline: medium none;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  color: #242424;
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  text-transform: normal;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 40px;
  font-weight: 500;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
p {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.7;
  color: #242424;
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}
*::-moz-placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}
*::placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}
.separator {
  border-top: 1px solid #f2f2f2;
}
/* button style */

.btn {
  -moz-user-select: none;
  background: #000080;
  border: medium none;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 15px 36px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  transition: 0.3s;
}
.btn:hover {
  color: #fff;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
}
.btn.grape-bg-btn {
  background: #000080;
}
/* search-modal */
#search-modal {
  background-color: rgba(23, 26, 33, 0.95);
  z-index: 999999;
}
.modal-backdrop {
  z-index: 1;
}
#search-modal .modal-dialog {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: none;
  outline: 0;
}
#search-modal .modal-dialog .modal-content {
  background: 0 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: #ffffff;
  padding: 50px 40px 30px 40px;
}
.modal-content-h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: #000080;
}
.modal-content-p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #595f69;
}
.form-check-input {
  width: 15px !important;
}
.social-button {
  width: 400px;
  height: 46px;
  background: #e8e9eb;
  border-radius: 14px;
  color: #000;
  font-size: 14px;
  border: none;
}
.login-line {
  height: 2px;
  background-color: #e8e9eb;
}
.modal-content-p-forgot {
  margin-left: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000080;
}
.form-check-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #595f69;
}
.form-col form input {
  width: 400px;
  font-size: 14px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 14px;
  background: 0 0;
  color: #000;
}
.form-col form input::-moz-placeholder {
  font-size: 14px;
}
.form-col form input::placeholder {
  font-size: 14px;
}

#search-modal .modal-dialog .modal-content form {
  /* width: 555px; */
  position: relative;
}
#search-modal .modal-dialog .modal-content form input {
  font-size: 14px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 14px;
  background: 0 0;
  color: #000;
}
#search-modal .modal-dialog .modal-content form input::-moz-placeholder {
  font-size: 14px;
}
#search-modal .modal-dialog .modal-content form input::placeholder {
  font-size: 14px;
}
.modal-content-button {
  width: 400px;
  height: 46px;
  background: #000080;
  border-radius: 14px;
  color: #ffffff;
  font-size: 14px;
  border: none;
}
.modal-content-button:hover {
  color: #fff;
  background: #000080;
}
.header-top {
  background: #343351;
}
.tranparent-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}
.main-menu ul li {
  display: inline-block;
  margin-left: 35px;
  position: relative;
}
.main-menu ul li > a {
  color: #000080;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  padding: 35px 0;
  position: relative;
}
.main-menu ul li > a::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  height: 2px;
  width: 0px;
  background: #d1eed2;
  transition: 0.3s;
  opacity: 0;
}
.mobile-menu.white-menu .mean-bar .meanmenu-reveal {
  border-color: #fff;
  color: #fff;
}
.mobile-menu.white-menu .mean-bar .meanmenu-reveal span {
  background: #fff;
}
.main-menu ul li.active a::before {
  width: 10px;
  opacity: 1;
}
.main-menu ul li:hover > a::before {
  width: 10px;
  opacity: 1;
}
.menu-icon {
}
.menu-icon a {
  font-size: 20px;
  color: #6a55a6;
}
.menu-icon a.search-modal {
  padding-right: 20px;
  border-right: 1px solid #d5d5d5;
}
.menu-icon a.shop-cart {
  padding-left: 20px;
  position: relative;
}
.menu-icon a.shop-cart::before {
  position: absolute;
  content: "0";
  width: 12px;
  height: 12px;
  right: -5px;
  top: 0px;
  background: #ff8a73;
  font-size: 8px;
  color: #fff;
  line-height: 12px;
  border-radius: 50%;
  text-align: center;
}
.single-cta-wrap {
  display: inline-block;
  margin-right: 35px;
  padding: 14px 0;
}
.header-top.ht-home5 {
  background: transparent;
}
.single-cta-wrap i {
  color: #fff;
  font-size: 15px;
  margin-right: 5px;
}
.single-cta-wrap span {
  color: #fff;
  font-size: 12px;
}
.header-social a {
  margin-left: 15px;
}
.header-social a i {
  color: #b6b6b6;
}
.header-top-h4 {
  background: #f3f6ff;
}
.second-htop-cta .single-cta-wrap i {
  color: #242424;
}
.second-htop-cta .single-cta-wrap span {
  color: #242424;
}
.header-social a:hover i {
  color: #1eb8ff;
}
.main-header.tranparent-header {
  top: 40px;
}
.home-four-menu ul li a {
  color: #fff;
}
.menu-icon.home-four-menu a {
  color: #fff;
}
.main-header.mh-home5 {
  background: #0006;
}
.main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 200px;
  background: #6a55a6;
  z-index: 9;
  padding: 10px 0;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.main-menu ul li:hover > .submenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.main-menu ul li .submenu li {
  display: block;
  text-align: left;
  margin: 0 20px;
}
.main-menu ul li .submenu li a:before {
  content: none;
}
.main-menu ul li .submenu li a {
  color: #fff;
  padding: 7px 0px;
  text-transform: capitalize;
  width: 100%;
}
.main-menu ul li .submenu li:hover a {
  padding: 7px 0px;
  padding-left: 10px;
}
.sticky {
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 9999;
  background: #fff;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}
.header-four.sticky {
  background: #6a55a6;
  top: 0;
}
.main-header.mh-home5.sticky {
  background: -webkit-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background: linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}

/* breadcrumb */
.breadcrumb-area {
  height: 500px;
  background-position: center;
  background-size: cover;
}
.breadcrumb-title h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
}
.breadcrumb-wrap {
  padding-top: 280px;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: block;
  border-radius: 0;
}
.breadcrumb-item {
  display: inline-block;
}
.breadcrumb-item::before {
  padding: 0 4px !important;
  color: #fff !important;
}
.breadcrumb-item a {
  color: #fff;
  text-transform: capitalize;
}
.breadcrumb-item.active {
  color: #ff8a73;
}

/* slider */
.slider-height {
  height: 1330px;
  background-position: left center;
  background-repeat: no-repeat;
  position: relative;
}
.slider-one-content {
  padding-top: 390px;
}
.home-four-slider {
  height: 1000px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.home-four-slider::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.75;
  z-index: -1;
  left: 0;
  top: 0;
}
.home-four-slider::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 270px;
  /* background-image: url('./static/img/bg/slider_shape.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
}
.home-four-slider .slider-wrapper {
  padding-top: 250px;
}
.home-four-slider.slider-home5 {
  height: 900px;
  position: relative;
}
.slider-h5-padding {
  padding-top: 400px;
}
.home-four-slider.slider-home5::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: -moz-linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background-image: -webkit-linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background-image: -ms-linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  opacity: 0.9;
}
.slider-shape {
  position: relative;
}
.slider-shape::before {
  content: "";
  position: absolute;
  left: 0;
  top: 300px;
  /* background: url('./static/img/slider/s-shape01.png'); */
  width: 213px;
  height: 470px;
}
.slider-shape-wrap {
  position: absolute;
  right: 0;
  bottom: -160px;
  width: 340px;
  height: 470px;
}
.s-slider-height {
  height: 900px;
  background-position: center;
  background-size: cover;
}
.slider-one-content span {
  font-size: 60px;
  text-transform: capitalize;
  color: #6a55a6;
  font-weight: 300;
  line-height: 1;
}
.slider-one-content h1 {
  font-size: 60px;
  font-weight: 900;
  color: #000080;
  margin-bottom: 18px;
}
.slider-one-content p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
}
.slider-btn a {
  margin-right: 15px;
}
.slider-one-img {
  padding-top: 180px;
}
.second-slider-height {
  height: 956px;
  background-size: cover;
  background-position: top center;
}
.second-slider-content span {
  font-size: 24px;
  font-weight: 600;
  color: #ff8a73;
}
.second-slider-content h1 {
  font-weight: 600;
  color: #242424;
  padding-right: 60px;
}
.second-slider-content p {
  font-size: 15px;
  padding-right: 90px;
}
.slider-two-img {
  margin-left: -205px;
  padding-top: 235px;
}
.second-slider-content {
  padding-top: 340px;
}
.slider-three-content span {
  font-size: 45px;
  font-weight: 700;
  color: #ff8a73;
  font-style: italic;
  line-height: 1;
}
.slider-three-content h1 {
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 20px;
}
.slider-three-content p {
  margin-bottom: 42px;
}
.slider-four-content span {
  color: #fff;
  font-style: normal;
}
.slider-four-content h1 {
  color: #fff;
  font-size: 58px;
}
.slider-four-content p {
  color: #fff;
  padding: 0px 205px;
}
.slider-active .slick-dots,
.slider-four-active .slick-dots {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 90px;
}
.slider-active .slick-dots li,
.slider-four-active .slick-dots li {
  display: inline-block;
  line-height: 1;
  margin: 0 5px;
}
.slider-active .slick-dots li.slick-active button,
.slider-four-active .slick-dots li.slick-active button {
  width: 30px;
  background: #ff8a73;
}
.slider-active .slick-dots li button,
.slider-four-active .slick-dots li button {
  text-indent: -9999999px;
  padding: 0;
  border: none;
  height: 9px;
  width: 20px;
  background: #6a55a6;
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
}
.slider-four-active .slick-dots {
  bottom: -190px;
  z-index: 9;
}
.slider-five-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -200px;
  font-size: 36px;
  padding: 0;
  border: none;
  background: none;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}
.slider-five-active .slick-next {
  right: -200px;
  left: auto;
}
.slider-five-btn .btn {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.slider-five-btn .btn:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #6a55a6;
}
.slider-five-btn .btn.grape-bg-btn {
  background: #fff;
  color: #6a55a6;
}
.slider-five-btn .btn.grape-bg-btn:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}
.slider-home5::after {
  display: none;
}
.slider-home5 .slider-wrapper {
  padding-top: 0;
}
/* features */
.section-title span {
  font-size: 20px;
  font-weight: 600;
  color: #000080;
  text-transform: capitalize;
}
.section-title h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.section-title.center-title h1 {
  margin-bottom: 21px;
  padding-bottom: 30px;
  position: relative;
}
.second-title span {
  text-transform: capitalize;
}
.second-title h1 {
  margin-bottom: 10px;
}
.second-title p {
  padding: 0 70px;
}
.center-title h1::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  /* background: url(../img/bg/title-shape.png); */
  width: 247px;
  height: 14px;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
}
.section-title p {
  margin-bottom: 0;
  padding: 0px;
}
.section-title.center-title p {
  padding: 0 50px;
}
.section-title.second-title p {
  padding: 0 75px;
}
.blue-icon {
  color: #5580ff;
}
.liteb-icon {
  color: #7bb9ff;
}
.green-icon {
  color: #45ba8d;
}
.red-icon {
  color: #ff616b;
}
.cyan-icon {
  color: #06b4fd;
}
.violet-icon {
  color: #cd68f4;
}
.lightv-icon {
  color: #6a55a6;
}
.darkg-icon {
  color: #fbb94c;
}
.single-features {
  border: 1px solid #ebebeb;
  padding: 25px 20px;
  position: relative;
  padding-top: 58px;
  transition: 0.3s;
  border-radius: 5px;
}
.features-icon {
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.features-icon i {
  font-size: 37px;
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  transition: 0.3s;
}
.features-content h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 7px;
}
.single-features.blue-f:hover {
  border-color: #5580ff;
}
.single-features.blue-f:hover .features-icon i {
  border-color: #5580ff;
  background: #5580ff;
  color: #fff;
}
.single-features.green-f:hover {
  border-color: #45ba8d;
}
.single-features.green-f:hover .features-icon i {
  border-color: #45ba8d;
  background: #45ba8d;
  color: #fff;
}
.single-features.red-f:hover {
  border-color: #ff616b;
}
.single-features.red-f:hover .features-icon i {
  border-color: #ff616b;
  background: #ff616b;
  color: #fff;
}
.second-features .features-icon {
  position: unset;
  margin-bottom: 10px;
}
.best-features.inner-features {
  background: #f4f9fd;
}
.second-features .features-icon i {
  height: unset;
  width: unset;
  border: none;
  border-radius: unset;
  font-size: 65px;
  line-height: 1;
  color: #6a55a6;
  transition: 0.3s;
}
.second-features:hover .features-icon i {
  color: #ff8a73;
}
.home-three-t span {
  text-transform: capitalize;
  font-style: italic;
}
.home-three-t h1 {
  font-weight: 900;
}
.home-three-t p {
  padding: 0px 40px;
}
.third-features-icon {
  position: relative;
  height: 100px;
  margin-bottom: 20px;
}
.third-features-icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* background: url(../img/icon/i_bg.png); */
  height: 100px;
  width: 100px;
  right: 0;
  margin: auto;
}
.third-features-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -112px;
  /* background: url(../img/icon/line.png); */
  width: 220px;
  height: 2px;
}
.third-features-icon.four-i::before,
.third-features-icon.four-i::after {
  content: none;
}
.third-features-icon i {
  font-size: 46px;
  line-height: 100px;
  text-align: center;
}
.third-features-content h3 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 13px;
}
.inner-fContent h3 {
  font-weight: 700;
}
.row
  .col-lg-4.col-md-6.text-center:last-child
  .third-features
  > .third-features-icon::after {
  content: none;
}
.third-features-icon.four-i {
  margin-bottom: 0;
}
.third-features.four-f {
  border: 1px solid #f5f5f5;
  padding-top: 25px;
  padding-bottom: 70px;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.3s;
}
.third-features.four-f:hover {
  border: 1px solid transparent;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
}
.solutions-title p {
  padding-left: 0;
  padding-right: 120px;
}
.solutions-wrap {
  position: relative;
}
.solutions-shape {
  height: 451px;
  width: 666px;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}
.solutions-icon i {
  font-size: 35px;
  color: #777777;
  line-height: 1;
}
.sigle-solutions h6 {
  font-size: 18px;
  font-weight: 800;
  transition: 0.3s;
}
.sigle-solutions p {
  margin-bottom: 0;
}
.sigle-solutions {
  border: 1px solid #ededed;
  padding: 25px 15px;
  border-radius: 5px;
  transition: 0.3s;
}
.sigle-solutions:hover {
  border-color: #6a55a6;
}
.sigle-solutions:hover h6 {
  color: #6a55a6;
}
.solutios-services.grey-bg {
  background: #f4f9fd;
}
.s_services-icon i {
  font-size: 45px;
  line-height: 1;
  transition: 0.3s;
}
.s_services-padding {
  padding: 1px;
}
.s_services-wrap {
  background: #fff;
  padding: 45px 30px;
}
.s_services-wrap:hover .s_services-content h5 {
  color: #6a55a6;
}
.s_services-wrap:hover .s_services-icon i {
  color: #ff8a73;
}
.s_service-num {
  font-size: 48px;
  font-weight: 900;
  background: linear-gradient(to bottom, #e9e9e9, #fff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  line-height: 0.7;
  margin-bottom: -15px;
}
.s_services-content h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 4px;
  transition: 0.3s;
}
.s_services-content p {
  margin-bottom: 0;
}
.slogan-wrap {
  background: -webkit-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  padding: 60px 40px;
  border-radius: 10px;
}
.slogan-wrap.ourVision {
  background: #ff8a73;
}
.slogan-content h3 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}
.slogan-content p {
  color: #fff;
  margin-bottom: 0px;
}
.best-s-pl {
  padding-left: 50px;
}
/* succeed */
.border-b {
  border-bottom: 1px solid #dee0e2;
}
.area-bg-wrapper {
  background-position: top center;
  background-repeat: no-repeat;
}
.single-succeed {
  padding: 30px 20px;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  transition: 0.3s;
}
.succeed-icon i {
  font-size: 50px;
  transition: 0.3s;
}
.succeed-content h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
  transition: 0.3s;
}
.succeed-content p {
  margin-bottom: 0;
  transition: 0.3s;
}
.single-succeed.green-s:hover {
  background: #45ba8d;
}
.single-succeed.green-s:hover .succeed-icon i,
.green-s:hover .succeed-content h3,
.green-s:hover .succeed-content p {
  color: #fff;
}
.single-succeed.blue-s:hover {
  background: #5580ff;
}
.single-succeed.blue-s:hover .succeed-icon i,
.blue-s:hover .succeed-content h3,
.blue-s:hover .succeed-content p {
  color: #fff;
}
.single-succeed.red-s:hover {
  background: #ff616b;
}
.single-succeed.red-s:hover .succeed-icon i,
.red-s:hover .succeed-content h3,
.red-s:hover .succeed-content p {
  color: #fff;
}
.single-succeed.red-s:hover {
  background: #ff616b;
}
.single-succeed.red-s:hover .succeed-icon i,
.red-s:hover .succeed-content h3,
.red-s:hover .succeed-content p {
  color: #fff;
}
.single-succeed.cyan-s:hover {
  background: #06b4fd;
}
.single-succeed.cyan-s:hover .succeed-icon i,
.cyan-s:hover .succeed-content h3,
.cyan-s:hover .succeed-content p {
  color: #fff;
}
.succeed-title h1 {
  padding-right: 60px;
}
.second-succeed-icon {
  margin-bottom: 15px;
}
.second-succeed-icon i {
  font-size: 45px;
  color: #242424;
  line-height: 1;
}
.single-succeed-wrap h6 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.single-succeed-wrap p {
  margin-bottom: 0;
}
.succeed-btn .btn:first-child {
  margin-right: 20px;
}
.area-bb {
  border-bottom: 1px solid #e2e2e2;
}
/* reports */
.reports-img {
  margin-left: -155px;
}
/* services */
.single-services-wrap {
  background: #fff;
  padding-top: 35px;
  transition: 0.3s;
}
.services-bg {
  background: #f3f9fd;
}
.single-services-wrap:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
.services-icon {
  margin-bottom: 15px;
}
.services-icon i {
  font-size: 45px;
  line-height: 1;
}
.services-content {
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 35px;
}
.services-content h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  transition: 0.3s;
}
.services-content p {
  margin-bottom: 0;
}
.services-bottom {
  padding: 15px 20px;
}
.read-more {
  float: left;
  display: block;
}
.bottom-icon {
  text-align: right;
  overflow: hidden;
  display: block;
}
.read-more a {
  font-size: 15px;
  color: #242424;
  font-weight: 600;
}
.bottom-icon a {
  font-size: 18px;
  line-height: 1;
  color: #242424;
}
.single-services-wrap.blue-s:hover .services-content h5 {
  color: #5580ff;
}
.single-services-wrap.blue-s:hover .read-more a,
.single-services-wrap.blue-s:hover .bottom-icon a {
  color: #5580ff;
}
.single-services-wrap.red-s:hover .services-content h5 {
  color: #ff616b;
}
.single-services-wrap.red-s:hover .read-more a,
.single-services-wrap.red-s:hover .bottom-icon a {
  color: #ff616b;
}
.single-services-wrap.green-s:hover .services-content h5 {
  color: #45ba8d;
}
.single-services-wrap.green-s:hover .read-more a,
.single-services-wrap.green-s:hover .bottom-icon a {
  color: #45ba8d;
}
.single-services-wrap.darkg-s:hover .services-content h5 {
  color: #fbb94c;
}
.single-services-wrap.darkg-s:hover .read-more a,
.single-services-wrap.darkg-s:hover .bottom-icon a {
  color: #fbb94c;
}
.single-services-wrap.violet-s:hover .services-content h5 {
  color: #cd68f4;
}
.single-services-wrap.violet-s:hover .read-more a,
.single-services-wrap.violet-s:hover .bottom-icon a {
  color: #cd68f4;
}
.single-services-wrap.cyan-s:hover .services-content h5 {
  color: #06b4fd;
}
.single-services-wrap.cyan-s:hover .read-more a,
.single-services-wrap.cyan-s:hover .bottom-icon a {
  color: #06b4fd;
}

.best_services.grey-bg {
  position: relative;
}
.services-img::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  /* background: url(../img/bg/services.jpg); */
  width: 44%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.services-title span {
  text-transform: capitalize;
}
.services-title h1 {
  margin-bottom: 10px;
}
.single-bs-wrap {
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
.single-bs-wrap:hover .bs-number {
  background: #6a55a6;
}
.single-bs-wrap:hover .bs-number h1 {
  color: #fff;
}
.single-bs-wrap:hover .bs-content h6 {
  color: #6a55a6;
}
.single-bs-wrap:last-child {
  border-bottom: none;
}
.bs-number {
  float: left;
  height: 60px;
  width: 60px;
  display: block;
  background: #f2f2f2;
  text-align: center;
  margin-right: 25px;
  transition: 0.3s;
}
.bs-number h1 {
  line-height: 60px;
  font-size: 36px;
  color: #6a55a6;
  font-weight: 700;
  margin-bottom: 0;
  transition: 0.3s;
}
.bs-content {
  overflow: hidden;
  display: block;
}
.bs-content h6 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
  transition: 0.3s;
}
.bs-content p {
  margin-bottom: 0;
}
.services-wrapper {
  position: relative;
}
.services-img-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
}
.services-item i {
  font-size: 45px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}
.s-content h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
}
.s-content p {
  margin-bottom: 0;
}
.services-item i {
  font-size: 45px;
  line-height: 1;
  margin-bottom: 10px;
  display: inline-block;
}
.s-services-item i {
  font-size: 63px;
  float: left;
  display: block;
  margin-right: 30px;
}
.s-services-item .s-content {
  overflow: hidden;
}
.s-services-item .s-content h5 {
  font-weight: 700;
}
/* app_task */
.task-title p {
  padding-right: 80px;
}
.task-icon {
  margin-bottom: 15px;
}
.task-icon i {
  font-size: 45px;
  color: #242424;
  line-height: 1;
}
.single-task-wrap h6 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.single-task-wrap p {
  margin-bottom: 0;
}
.task-btn .btn:first-child {
  margin-right: 20px;
}
/* our-skill */
.skill-img {
  margin-left: -320px;
}
.progressbar-percetage {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  right: 0;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 900;
  line-height: 1;
}
.progress-bar-wrap {
  float: left;
  display: block;
  position: relative;
  width: 95px;
  height: 95px;
  margin-right: 15px;
}
.skill-range {
  position: absolute;
  line-height: 1;
  left: 25px;
  top: 36px;
}
.pie-chart-percent {
  font-size: 22px;
  font-weight: 900;
}
.pie-chart-percent sup {
  font-size: 20px;
  top: 0;
  left: -4px;
}
.progress-content {
  overflow: hidden;
  display: block;
}
.progress-content h5 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.progress-content p {
  margin-bottom: 0;
}
.second-skill-area.w-bg {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
}
.second-skill-area.border-bn {
  border-bottom: none;
}
.d-app-img.skill-app {
  margin-left: -370px;
  position: relative;
}
.single-app {
  position: absolute;
  left: 262px;
  top: 173px;
}
.single-app img {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.skill-app img {
  width: 100%;
}
.app-img .watch img,
.app-img .shadow img {
  width: 100%;
}
.watch {
  position: relative;
  z-index: 9;
  animation-name: app-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: app-animation;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: app-animation;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: app-animation;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: app-animation;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.shadow {
  position: absolute;
  bottom: 51px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  z-index: 1;
}
@-webkit-keyframes app-animation {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
/* portfolio */
.portfolio-area {
  border-bottom: 1px solid #ebebeb;
}
.portfolio-item {
  position: relative;
  overflow: hidden;
}
.portfolio-thumb img {
  width: 100%;
}
.p-overly {
  position: absolute;
  left: 35px;
  bottom: 45px;
  transition: 0.3s;
  z-index: 9;
  opacity: 0;
}
.p-overly h4 {
  font-size: 15px;
  color: #ff8a73;
  margin-bottom: 0;
  font-weight: 400;
  text-transform: uppercase;
}
.p-overly h4:hover a {
  color: #ff8a73;
}
.p-overly span {
  font-size: 18px;
  color: #fff;
}
.portfolio-thumb {
  position: relative;
}
.portfolio-thumb::before {
  position: absolute;
  content: "";
  left: 15px;
  top: 15px;
  background: #000000;
  transition: 0.3s;
  opacity: 0;
  right: 15px;
  bottom: 15px;
}
.portfolio-item:hover .p-overly {
  opacity: 1;
  bottom: 35px;
}
.portfolio-item:hover .portfolio-thumb::before {
  opacity: 0.75;
}
.best-project-wrap {
  border-top: 1px solid #e3e3e3;
}
.single-project {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.project-thumb {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.project-thumb::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: 0.3s;
}
.project-overly.text-center {
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-50%);
  right: 0;
  margin: auto;
  z-index: 9;
  transition: 0.3s;
  opacity: 0;
}
.project-overly h6 {
  color: #ff8a73;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 15px;
}
.project-overly span {
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
}
.single-project:hover .project-overly.text-center {
  opacity: 1;
  top: 50%;
}
.single-project:hover .project-thumb::before {
  opacity: 0.75;
}
.inner-portfolio-thumb {
  position: relative;
}
.portfolio-inner {
  position: relative;
}
.inner-portfolio-thumb img {
  width: 100%;
}
.portfolio-content {
  position: absolute;
  text-align: center;
  bottom: -40px;
  left: 40px;
  right: 40px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: 0.5s;
}
.portfolio-content.portfolio-g {
  position: absolute;
  width: 285px;
  left: 30px;
  bottom: -85px;
  background: linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background: -webkit-linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}
.portfolio-columan-content {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: -85px;
  background: linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background: -webkit-linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}
.p-second-inner {
  overflow: hidden;
}
.inner-portfolio-thumb::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 0;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.5;
  transition: 0.5s;
  right: 0;
  margin: auto;
}
.second-pthumb::before {
  margin: unset;
}
.portfolio-content span {
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s;
  color: #c4c4c4;
}
.portfolio-content h6 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  color: #242424;
}
.portfolio-content.portfolio-g span,
.portfolio-columan-content span {
  color: #fff;
}
.portfolio-content.portfolio-g h6,
.portfolio-columan-content h6 {
  color: #fff;
}
.portfolio-content h6:hover a {
  color: #fff;
}
.portfolio-inner:hover .inner-portfolio-thumb::before {
  width: 100%;
}
.portfolio-inner:hover .portfolio-content {
  background: linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background: -webkit-linear-gradient(
    160deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}
.portfolio-inner:hover .portfolio-content h6,
.portfolio-inner:hover .portfolio-content span {
  color: #fff;
}
.portfolio-inner:hover .portfolio-content.portfolio-g {
  bottom: 30px;
}
.portfolio-inner:hover .portfolio-columan-content {
  bottom: 30px;
}
.single-portfolio-thumb img {
  width: 100%;
}
.single-portfolio-title h1 {
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 0;
}
.single-portfolio-title h1 span {
  font-size: 24px;
  font-weight: 700;
}
.s-portfolio-content p {
  margin-bottom: 0;
}
.project-status {
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.14);
  padding: 50px 55px;
  border-radius: 10px;
}
.project-status-title h6 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.project-status li span {
  font-size: 18px;
  font-weight: 600;
}
.project-status ul {
  margin-bottom: 25px;
}
.project-share-wrap h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.project-share-wrap a i {
  color: #a7a7a7;
  margin: 0 5px;
}
.project-prev a,
.project-next a {
  height: 50px;
  width: 50px;
  border: 1px solid #eeeeee;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  color: #242424;
  transition: 0.3s;
}
.project-prev a:hover,
.project-next a:hover {
  border-color: transparent;
  color: #fff;
  background: linear-gradient(
    -180deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background: -webkit-linear-gradient(
    -180deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}
.project-prev {
  float: left;
  display: inline-block;
}
.project-next {
  overflow: hidden;
  display: block;
}
.project-menu-wrap {
  position: relative;
  z-index: 1;
}

.project-menu-wrap > a {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
  height: 50px;
  width: 50px;
  margin: auto;
  display: inline-block;
  line-height: 50px;
  background: linear-gradient(
    -180deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background: -webkit-linear-gradient(
    -180deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  border-radius: 50%;
  color: #fff;
  z-index: 9;
}
.single-portfolio-area.pt-100.pb-50 {
  border-bottom: 1px solid #eeeeee;
}
.r-post-title h4 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0;
}
/* work-process */
.s-number {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  border: 1px dashed #e3e3e3;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 800;
  color: #e3e3e3;
  line-height: 60px;
  font-family: "Nunito Sans", sans-serif;
  margin-left: 48px;
}
.wp-content {
  padding-left: 50px;
  padding-top: 25px;
  padding-bottom: 30px;
}
.work-process
  .row
  .col-12:nth-child(2)
  > .work-process-wrapper
  .row
  .col-lg-6:first-child {
  order: 12;
}
.wp-inner {
  padding-top: 25px;
}
.wp-border-l {
  border-left: 1px dashed #e3e3e3;
}
.wp-border-t {
  border-top: 1px dashed #e3e3e3;
}
.wp-border-b {
  border-bottom: 1px dashed #e3e3e3;
}
/* best-features */
.best-features-icon {
  line-height: 1;
  margin-bottom: 13px;
}
.best-features-icon i {
  font-size: 45px;
}
.best-features-content h4 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 9px;
  transition: 0.3s;
}
.best-features-content p {
  margin-bottom: 0;
}
.best-features-wrap.green-bf:hover .best-features-content h4 {
  color: #45ba8d;
}
.best-features-wrap.red-bf:hover .best-features-content h4 {
  color: #ff616b;
}
.best-features-wrap.blue-bf:hover .best-features-content h4 {
  color: #5580ff;
}
.best-features-wrap.violet-bf:hover .best-features-content h4 {
  color: #cd68f4;
}
.best-features-wrap.darkg-bf:hover .best-features-content h4 {
  color: #fbb94c;
}
.best-features-wrap.cyan-bf:hover .best-features-content h4 {
  color: #06b4fd;
}
.best-features-wrap.cyan-bf:hover .best-features-content h4 {
  color: #06b4fd;
}
.best-features-wrap.lightv-bf:hover .best-features-content h4 {
  color: #6a55a6;
}
.best-features-wrap.liteb-bf:hover .best-features-content h4 {
  color: #7bb9ff;
}
.second-skill-area {
  background: #f3f9fd;
  position: relative;
}
.second-skill-bg {
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 49.4%;
  height: 100%;
  background-position: center;
}
.single-skill {
  position: relative;
}
.single-skill .progress .progress-bar span {
  position: absolute;
  top: 0;
  right: 0;
  color: #242424;
  font-size: 15px;
}
.single-skill h6 {
  font-size: 18px;
  font-weight: 600;
}
.single-skill .progress {
  height: 3px;
  background: #dcdcdc;
}
.red-bg {
  background: #ff616b;
}
.green-bg {
  background: #45ba8d;
}
.blue-bg {
  background: #5580ff;
}
.cyan-bg {
  background: #06b4fd;
}
.violet-bg {
  background: #cd68f4;
}
/* our-offer */
.offer-padding {
  padding-left: 33px;
}
.offer-img img {
  width: 100%;
}
.offer-icon {
  float: left;
  height: 25px;
  width: 25px;
  display: block;
  border: 1px solid #ff8a73;
  text-align: center;
  line-height: 25px;
  color: #6a55a6;
  border-radius: 50%;
  margin-right: 15px;
  transition: 0.3s;
}
.offer-content {
  overflow: hidden;
  display: block;
  width: inherit;
}
.offer-content h5 {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 3px;
}
.offer-content p {
  margin-bottom: 0px;
}
.single-offer:hover .offer-icon {
  color: #fff;
  background: #6a55a6;
  border-color: #6a55a6;
}
/* app-display */
.app-area {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.app-padding-b {
  padding-bottom: 87px;
}
.single-app-features {
  border-bottom: 1px dashed #c3c4c4;
}
.single-app-features:last-child {
  border-bottom: none;
}
.app-features-icon {
  float: left;
  display: block;
  width: 50px;
  margin-right: 20px;
}
.app-features-icon i {
  font-size: 48px;
  line-height: 1;
}
.app-features-content {
  overflow: hidden;
  display: block;
  width: inherit;
}
.app-features-content h5 {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 2px;
}
.app-features-content p {
  margin-bottom: 0px;
}
.app-img {
  padding-top: 20px;
}
.app-img.animation-app {
  position: relative;
  padding-bottom: 135px;
}
.download-bg {
  background: #563ba2;
}
.d-app-img {
  margin-left: -300px;
}
.download-title h1 {
  color: #fff;
}
.download-title p {
  color: #fff;
  padding: 0;
}
.download-btn a:first-child {
  margin-right: 15px;
}
/* pricing */
.pricing-box {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 38px 0px rgba(68, 68, 68, 0.15);
  border-radius: 5px;
  padding-top: 25px;
  padding-bottom: 30px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s linear;
  transform: translateY(0);
}
.pricing-head h4 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
}
.pricing-head p {
  margin-bottom: 0;
}
.pricing-head {
  padding-bottom: 25px;
  border-bottom: 1px dashed #e5e5e5;
  margin-bottom: 20px;
}
.price-count h2 {
  font-size: 36px;
  font-weight: 900;
  color: #000080;
  margin-bottom: 0;
}
.price-count h2 > span {
  font-size: 24px;
  color: #242424;
  font-weight: 700;
}
.price-list {
  margin-bottom: 245px;
}
.price-list ul li {
  margin-bottom: 12px;
}
.price-list ul li:last-child {
  margin-bottom: 0px;
}
.pricing-box.active .price-count h2 {
  color: #003602d2;
}
.pricing-box.active .btn.grape-bg-btn {
  background: #003602;
}
.pricing-box.active {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 38px 0px rgba(106, 85, 166, 0.4);
  transform: translateY(-10px);
}
.area-background {
  background-position: center;
  background-size: cover;
}
.pricing-area .border-b {
  border-bottom: 1px solid #dddddd;
}
.second-pricing-box {
  background: #fff;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.p-head {
  background: #f2f9fd;
  padding: 30px 0;
}
.p-head i {
  font-size: 50px;
  color: #b4babe;
  line-height: 1;
  margin-bottom: 10px;
  display: inline-block;
}
.p-head h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  transition: 0.3s;
  font-style: italic;
}
.p-head h5 {
  font-size: 15px;
  font-weight: 800;
  color: #000080;
  margin-bottom: 0;
}
.p-head h5 span {
  color: #242424;
  font-weight: 400;
}
.p-list {
  padding: 25px 0;
}
.p-list ul li {
  margin-bottom: 6px;
}
.p-list ul li:last-child {
  margin-bottom: 0;
}
.p-btn .btn {
  margin-bottom: -22px;
}
.second-pricing-box:hover .p-head h4 {
  color: #563ba2;
}
.second-pricing-box:hover .p-btn .btn {
  background: #563ba2;
}
.second-pricing-plan.pb-125.pt-100 {
  border-top: 1px solid #d7d8dd;
}
/* funfact */
.funfact-area {
  overflow: hidden;
}
.single-fact {
  position: relative;
  overflow: hidden;
}
.fact-img {
  position: relative;
  overflow: hidden;
}
.fact-img img {
  width: 100%;
}
.fact-img::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.75;
  left: 0;
  top: 0;
}
.fact-overly {
  position: absolute;
  left: 30px;
  bottom: 30px;
  right: 30px;
  z-index: 9;
}
.fact-number span {
  color: #fff;
  display: block;
  font-size: 130px;
  font-weight: 700;
  opacity: 0.1;
  line-height: 1;
}
.fact-content h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
}
.fact-content p {
  color: #fff;
  margin-bottom: 15px;
  opacity: 0;
  transition: 0.5s;
  visibility: hidden;
  transform: scale(0.5);
}
.fact-btn i {
  font-size: 30px;
  color: #ff8a73;
  transform: rotate(180deg);
  display: inline-block;
  transition: 0.5s;
  z-index: 99;
}
.fact-content {
  transform: translateY(33%);
  transition: 0.5s;
}
.single-fact:hover .fact-content {
  transform: translateY(0);
}
.single-fact:hover .fact-content p {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.single-fact:hover .fact-btn i {
  transform: rotate(90deg);
}
/* testimonial */
.home4-testimonial {
  border-bottom: none;
  position: relative;
}
.position {
  position: relative;
}
.bg-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100px;
  margin: auto;
}
.bg-text h2 {
  margin-bottom: 0;
  font-size: 100px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 21px;
  opacity: 0.05;
  line-height: 1;
}
.testimonial-img {
  display: inline-block;
  position: relative;
}
.phrase-icon {
  position: absolute;
  right: 0;
  bottom: -5px;
  z-index: 9;
}
.phrase-icon i {
  height: 40px;
  width: 40px;
  background: #6a55a6;
  display: block;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 50%;
}
.client-text p {
  font-size: 18px;
  margin-bottom: 0;
}
.client-info h5 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.testimonial-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -250px;
  background: none;
  border: 1px solid #afadad;
  color: #afadad;
  height: 60px;
  width: 60px;
  font-size: 24px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  z-index: 9;
}
.testimonial-active .slick-next {
  right: -250px;
  left: auto;
}
.testimonial-active .slick-arrow:hover {
  background: #ff8a73;
  border-color: #ff8a73;
  color: #fff;
}
.testimonial-title span {
  text-transform: capitalize;
}
.testimonial-title h1 {
  margin-bottom: 10px;
}
.testimonial-title p {
  padding: 0 70px;
}
.testimonial-wrapper {
  margin-left: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 50px 0;
  padding-right: 30px;
  padding-left: 70px;
  position: relative;
}
.testimonial-img-wrap {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
.client-text-wrap i {
  font-size: 28px;
  color: #6a55a6;
  margin-bottom: 20px;
  line-height: 1;
  display: block;
}
.client-text-wrap h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 3px;
}
.client-text-wrap p {
  margin-bottom: 0px;
}
.second-testimonial-active .slick-dots {
  text-align: center;
  bottom: -50px;
  position: relative;
}
.second-testimonial-active .slick-dots li {
  display: inline-block;
  line-height: 1;
}
.second-testimonial-active .slick-dots li.slick-active button {
  width: 25px;
  background: #ff8a73;
}
.second-testimonial-active .slick-dots li button {
  text-indent: -99999px;
  padding: 0;
  border: none;
  height: 8px;
  width: 20px;
  background: #e3e3e3;
  margin: 0 3px;
  border-radius: 30px;
  cursor: pointer;
}
.testimonial-icon i {
  font-size: 35px;
  line-height: 1;
}
.single-testimonial-wrapper {
  position: relative;
}
.t-content {
  padding: 25px 40px;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  margin: 0px 25px;
  border-radius: 10px;
}
.single-testimonial-wrapper::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 96%;
  height: 245px;
  background: #fff;
  z-index: -1;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transform: translateY(-50%);
  right: 0;
  margin: auto;
}
.single-testimonial-wrapper::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 100%;
  height: 205px;
  background: #fff;
  z-index: -2;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transform: translateY(-50%);
}
.testimoial-inner-content p {
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.testimoial-inner-content p::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 1px;
  background: #dcdcdc;
  bottom: 0;
}
.client-inf h6 {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 0;
}
.client-inf span {
  font-weight: 600;
  color: #ff8a73;
}
.third-testimonial-active .slick-track {
  padding: 30px 0;
}
.third-testimonial-active .slick-dots {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
}
.third-testimonial-active .slick-dots li {
  display: inline-block;
  line-height: 1;
}
.third-testimonial-active .slick-dots li.slick-active button {
  background: #563ba2;
  width: 15px;
  border-color: #563ba2;
  border-radius: 30px;
}
.third-testimonial-active .slick-dots li button {
  text-indent: -99999px;
  padding: 0;
  height: 8px;
  width: 8px;
  border: 1px solid #b7b7b7;
  border-radius: 50%;
  margin: 0 3px;
  line-height: 1;
  cursor: pointer;
  background: transparent;
}
/* team */
.area-wrap.tb-bg {
  background: #f4f9fd;
}
.team-bg-wrap {
  background-position: center center;
  background-size: cover;
}
.single-team-wrap {
  padding: 40px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}
.team-info h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  transition: 0.3s;
}
.team-info span {
  transition: 0.3s;
}
.team-social a {
  margin: 0 5px;
}
.team-social a i {
  transition: 0.3s;
}
.fa-facebook-f {
  color: #4267b2;
}
.fa-twitter {
  color: #1da1f2;
}
.fa-google-plus-g {
  color: #fa5d4e;
}
.single-team-wrap:hover {
  background: #563ba2;
}
.single-team-wrap:hover .team-social a i {
  color: #fff;
}
.single-team-wrap:hover .team-info h5,
.single-team-wrap:hover .team-info span {
  color: #fff;
}
.team-area-wrap {
  background: #f4f9fd;
}
.team-vector img {
  width: 100%;
}
.inner-team-wrapper {
  background-position: bottom center;
  background-repeat: no-repeat;
}
.inner-team-area {
  position: relative;
}
.inner-team-area::before {
  position: absolute;
  content: "";
  top: 304px;
  right: 0;
  /* background: url(../img/bg/team_inner_shape.png); */
  width: 326px;
  height: 357px;
}
.team-inner-title {
  margin-bottom: 45px;
}
.inner-bg-wrapper {
  background-position: bottom center;
  background-repeat: no-repeat;
}
.inner-ptop-area {
  position: relative;
}
.inner-ptop-area::before {
  position: absolute;
  content: "";
  top: 304px;
  right: 0;
  /* background: url(../img/bg/team_inner_shape.png); */
  width: 326px;
  height: 357px;
}
/* project */
.projects-area.boder-b {
  border-bottom: 1px solid #dcdcdc;
}
.projects-img img {
  width: 100%;
}
.projects-img.project-prnt {
  position: relative;
}
.projects-img.project-prnt img {
  width: unset;
}
.project-prnt .second-project-img {
  position: absolute;
  left: 30px;
  top: 50px;
}
.conunter-icon {
  float: left;
  width: 45px;
  height: 45px;
  line-height: 45px;
  margin-right: 10px;
}
.conunter-icon i {
  font-size: 42px;
  color: #919192;
}
.counter {
  font-size: 24px;
  font-weight: 900;
  line-height: 1;
  overflow: hidden;
  transition: 0.3s;
}
.conuter-wrap p {
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
}
.best-project {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bp-title p {
  padding: 0;
}
.bp-content ul li {
  position: relative;
  margin-bottom: 5px;
  margin-left: 20px;
}
.bp-content ul li::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  width: 10px;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
}
.bp-content ul li:last-child {
  margin-bottom: 0;
}
.bp-img {
  position: relative;
}
.bp-img img {
  width: unset;
}
.counter-area.counter-bg {
  background-image: -moz-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background-image: -webkit-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background-image: -ms-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}
.single-count-wrap.home5-count .counter {
  color: #fff;
}
.single-count-wrap.home5-count p {
  color: #fff;
}
/* counter */
.count .second-counter {
  font-size: 60px;
  font-weight: 300;
  line-height: 1;
  color: #242424;
}
.count > span {
  font-size: 50px;
  font-weight: 300;
  color: #ff8a73;
  line-height: 1;
}
.single-count-wrap p {
  font-size: 24px;
  color: #777777;
  margin-bottom: 0;
}
/* .cta */
.second-cta {
  background-size: cover;
  background-position: center;
  height: 600px;
}
.cta-t h1 {
  color: #fff;
  font-size: 50px;
  margin-bottom: 8px;
}
.cta-t p {
  color: #fff;
  padding: 0 45px;
  font-size: 18px;
}
.subs-form {
  position: relative;
}
.subs-form input {
  width: 100%;
  border: none;
  background: #fff;
  padding: 25px 40px;
  border-radius: 50px;
}
.subs-form input::placeholder {
  font-size: 18px;
  color: #c1c1c1;
}
.subs-form .btn {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  padding: 18px 55px;
  font-size: 24px;
  font-weight: 900;
  border-radius: 50px;
}
.subs-form.home5_form .btn {
  background-image: -moz-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background-image: -webkit-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
  background-image: -ms-linear-gradient(
    120deg,
    rgb(45, 25, 103) 0%,
    rgb(96, 55, 214) 88%
  );
}
/* brand */
.bg-wrap {
  background-position: center;
  background-size: cover;
}
.brand-area.border-b {
  border: none;
}
.brand-area {
  border-bottom: 1px solid #dcdcdc;
}
.single-brand {
  text-align: center;
}
.single-brand img {
  display: inline-block;
}
/* get-start */
.get-start-title p {
  font-size: 18px;
  padding: 0 50px;
}
.get-start-btn .btn {
  margin: 0 8px;
}
/* screenshot */
.screenshot-active::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  /* background: url(../img/screenshot/mac.png); */
  width: 1200px;
  height: 680px;
  background-repeat: no-repeat;
  z-index: 99;
  background-position: center;
}
.single-screenshot {
  text-align: center;
}
.single-screenshot img {
  display: inline-block;
}
.screenshot-active .slick-track {
  padding: 56px 0;
}
.single-screenshot.slick-slide img {
  box-shadow: 0px 7px 28px 2px rgba(106, 85, 166, 0.4);
}
.single-screenshot.slick-active.slick-center img {
  box-shadow: none;
}
/* contact */
.cinfo-list ul li {
  position: relative;
}
.cinfo-list ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 1px;
  background: #ff8a73;
}
.cinfo-list ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 8px;
}
.wcontact-btn .btn {
  font-weight: 600;
  font-size: 15px;
  padding: 15px 25px;
}
.wcontact-map {
  position: relative;
}
.wcontact-map img {
  width: 100%;
}
.map-marker {
  position: absolute;
  cursor: pointer;
}
.map-c1 {
  top: 7%;
  left: 36%;
}
.map-c2 {
  top: 12%;
  left: 18%;
}
.map-c3 {
  top: 18%;
  left: 25%;
}
.map-c4 {
  top: 25%;
  left: 10%;
}
.map-c5 {
  top: 30%;
  left: 26%;
}
.map-c6 {
  top: 41%;
  left: 20%;
}
.map-c7 {
  left: 29%;
  top: 62%;
}
.map-c8 {
  left: 23%;
  top: 75%;
}
.map-c9 {
  left: 49%;
  top: 34%;
}
.map-c10 {
  top: 57%;
  left: 47%;
}
.map-c11 {
  top: 72%;
  left: 57%;
}
.map-c12 {
  right: 11%;
  top: 8%;
}
.map-c13 {
  right: 17%;
  top: 22%;
}
.map-c14 {
  right: 18%;
  top: 38%;
}
.map-c15 {
  top: 25%;
  right: 26%;
}
.map-c16 {
  top: 49%;
  right: 27%;
}
.map-c17 {
  right: 18%;
  top: 62%;
}
.map-c18 {
  right: 18%;
  top: 83%;
}
.map-c19 {
  top: 83%;
  right: 7%;
}
.map-c20 {
  top: 15%;
  left: 59%;
}
.map-marker-icon {
  background: #ff8a73;
  height: 5px;
  width: 5px;
  cursor: pointer;
}
.map-address.text-center {
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 23.5px 1.5px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
  transition: 0.3s;
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}
.map-marker:hover .map-address.text-center {
  display: block;
  opacity: 1;
  visibility: visible;
}
.map-address h6 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
}
.map-address span {
  font-size: 12px;
}
.contact-title span {
  text-transform: capitalize;
  color: #242424;
  font-weight: 600;
}
.contact-title span {
  text-transform: capitalize;
  color: #242424;
  font-weight: 600;
}
.contact-img img {
  width: 100%;
}
.contact-cta {
  box-shadow: 9px 0 30px 1px rgba(0, 0, 0, 0.1);
  padding: 75px 0;
}
.contact-cta-icon i {
  font-size: 55px;
  color: #000080;
  line-height: 1;
}
.contact-cta-content h5 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.contact-cta-content span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.paddingX {
  padding-left: 0px;
  padding-right: 0px;
}
.cta-padding-l {
  padding-left: 0;
}
.cta-padding-r {
  padding-right: 0;
}
.contact-map-wrap {
  position: relative;
}
.contact-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 47%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.form-title h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.contact-form input {
  width: 100%;
  border: 1px solid #dddddd;
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.contact-form textarea {
  width: 100%;
  border: 1px solid #dddddd;
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 30px;
  height: 240px;
}
.contact-form button {
  margin-bottom: 30px;
}
/* blog */
.blog-thumb img {
  width: 100%;
}
.blog-meta i {
  display: inline-block;
  margin-right: 5px;
}
.b-content-box {
  padding: 25px;
  border: 1px solid #f2f2f2;
  background: #fff;
}
.b-content-box .blog-meta {
  margin-bottom: 5px;
}
.blog-content h5 {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 9px;
  transition: 0.3s;
}
.blog-content h5:hover a {
  color: #ff8a73;
}
.blog-content p {
  margin-bottom: 0;
}
.blog-link a {
  font-weight: 700;
  color: #242424;
}
.blog-link a i {
  transition: 0.3s;
  margin-left: 10px;
  opacity: 0;
  position: relative;
  top: 1px;
}
.blog-wrapper {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.blog-wrapper:hover {
  box-shadow: 0px 0px 35px 2px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 0px 35px 2px rgba(0, 0, 0, 0.09);
}
.blog-wrapper:hover .blog-content h5 {
  color: #ff8a73;
}
.blog-wrapper:hover .blog-link a > i {
  margin-left: 3px;
  opacity: 1;
}
.blog-wrapper:hover .blog-link a {
  color: #ff8a73;
}
.single-blog-wrapper .blog-thumb {
  position: relative;
}
.single-blog-wrapper .blog-thumb::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0;
  transition: 0.3s;
}
.single-blog-wrapper:hover .blog-thumb::before {
  opacity: 0.3;
}
.blog-inner {
  padding: 25px 20px;
  background: #f1f9fd;
  border-radius: 0px 0px 10px 10px;
}
.blog-link h5 {
  font-size: 18px;
  margin-bottom: 0;
}
.blog-link h5 > a {
  font-weight: 800;
  color: #242424;
}
.single-blog-wrapper:hover .blog-link h5 > a {
  color: #6a55a6;
}
.w-about-wrap {
  padding: 50px 20px;
  background: #f4f9fd;
}
.about-content h6 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 3px;
}
.about-content p {
  margin-bottom: 0;
}
.sidebar-search form {
  position: relative;
}
.sidebar-search input {
  width: 100%;
  border: none;
  background: #f4f9fd;
  padding: 20px 30px;
  border-radius: 30px;
}
.sidebar-search button {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  color: #8a8989;
  cursor: pointer;
}
.siderbar-title h5 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.rc-post-thumb {
  float: left;
  width: 116px;
  height: auto;
  margin-right: 15px;
}
.sidebar-rc-post ul {
  overflow: hidden;
}
.sidebar-rc-post li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.sidebar-rc-post li:last-child {
  margin-bottom: 0px;
}
.rc-post-content {
  overflow: hidden;
  display: block;
}
.rc-post-content .post-meta {
  font-size: 12px;
  color: #8b8a8a;
}
.rc-post-content h4 {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 0;
}
.rc-post-content h4:hover a {
  color: #ff8a73;
}
.sidebar-categories ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
}
.sidebar-categories ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 12px;
  width: 12px;
  border: 1px solid #8a8989;
  transform: translateY(-50%);
  border-radius: 50%;
}
.sidebar-categories ul li a {
  color: #8a8989;
}
.sidebar-categories ul li:hover a {
  color: #ff8a73;
}
.sidebar-tag ul li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 10px;
}
.sidebar-tag ul li a {
  background: #f4f9fd;
  display: block;
  padding: 10px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 600;
  color: #242424;
}
.sidebar-tag ul li:hover a {
  background: #ff8a73;
  color: #fff;
}
.sidebar-instagram ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 110px;
}
.sidebar-instagram ul li a {
  display: block;
  position: relative;
}
.sidebar-instagram ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: 0.3s;
}
.sidebar-instagram ul li:hover a:before {
  opacity: 0.5;
}
.blog-details-thumb img {
  width: 100%;
}
.blog-details-content h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-details-content p {
  margin-bottom: 0;
}
blockquote.blockquote {
  margin: 25px 0;
  padding: 30px 55px;
  font-size: 24px;
  color: #6a55a6;
  border: 1px solid #dedede;
  border-radius: 5px;
  position: relative;
  padding-left: 140px;
}
blockquote.blockquote i {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 45px;
  color: #ff8a73;
}
.post-share-wrap span {
  font-size: 18px;
  font-weight: 600;
  margin-right: 12px;
}
.post-share-wrap a i {
  color: #8a8989;
  display: inline-block;
  margin-right: 10px;
}
.post-meta-info ul li {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  margin-right: 20px;
}
.post-meta-info ul li::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  width: 1px;
  height: 15px;
  background: #bebebe;
  transform: translateY(-50%);
}
.post-meta-info ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.post-meta-info ul li:last-child::before {
  content: none;
}
.post-meta-info ul li a i {
  color: #6a55a6;
  margin-right: 10px;
}
.post-meta-info ul li a {
  color: #242424;
}
.author-comments-avatar {
  float: left;
  display: block;
}
.author-comment-box {
  overflow: hidden;
  background: #f4f9fd;
  border-radius: 60px 0 0 60px;
}
.author-comments-text {
  overflow: hidden;
  display: block;
  padding: 23px;
}
.author-comments-text h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.author-comments-text p {
  margin-bottom: 0;
}
.details-title h3 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0;
}
.single-comment .comment-avatar {
  float: left;
  display: block;
  margin-right: 20px;
}
.single-comment .comment-text {
  overflow: hidden;
  display: block;
}
.comment-text p {
  margin-bottom: 10px;
}
.latest-comments ul li {
  padding-bottom: 25px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 25px;
}
.latest-comments ul li:nth-child(2) {
  padding-left: 80px;
}
.latest-comments ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.avatar-name {
  float: left;
  margin-right: 12px;
}
.avatar-name h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.post-date {
  overflow: hidden;
}
.post-date span {
  font-size: 12px;
  color: #ff8a73;
}
.comment-form input {
  width: 100%;
  border: 1px solid #dcdcdc;
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.comment-form input::placeholder,
.comment-form textarea::placeholder {
  font-size: 18px;
  color: #8a8989;
}
.comment-form textarea {
  width: 100%;
  border: 1px solid #dcdcdc;
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 30px;
  max-height: 240px;
}
/* shop */
.single-shop {
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.15);
}
.product-img img {
  width: 100%;
}
.product-info {
  float: left;
  display: block;
}
.product-price {
  text-align: right;
  overflow: hidden;
}
.product-content {
  overflow: hidden;
  padding: 20px;
}
.product-info h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.pro-rating i {
  color: #fbcb23;
  margin-right: 6px;
}
.pro-rating .no-rating {
  color: #b9b9b9;
}
.product-price h6 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 800;
  margin-top: 8px;
}
/* footer */
.footer-bg {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer-text p {
  color: #fff;
  margin-bottom: 0;
}
.footer-widget-headig h5 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 32px;
}
.footer-widget ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}
.footer-widget ul li::before {
  left: 0;
  top: 6px;
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  border: 1px solid #8a8989;
  border-radius: 50%;
  margin-right: 7px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
}
.footer-widget ul li:last-child {
  margin-bottom: 0px;
}
.footer-widget ul li a {
  font-size: 18px;
  color: #fff;
}
.footer-widget ul li:hover::before {
  border-color: #ff8a73;
  background: #ff8a73;
}
.footer-widget ul li:hover a {
  color: #ff8a73;
}
.footer-widget.white-bg {
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  border-radius: 5px;
}
.footer-widget.white-bg h5 {
  color: #333;
}
.cta-icon {
  float: left;
  display: block;
  margin-right: 20px;
}
.cta-icon i {
  font-size: 18px;
  margin-top: 5px;
}
.single-cta p {
  overflow: hidden;
  width: inherit;
  margin-bottom: 0;
  font-size: 16px;
}
.footer-social a {
  font-size: 18px;
  color: #bebebe;
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  background: #fff;
  margin: 0 2px;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
}
.footer-social a > i {
  color: #bebebe;
  transition: 0.3s;
}
.footer-social a:hover > i {
  color: #fff;
}
.footer-social a:hover {
  background: #4267b2;
}
.footer-social {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.footer-widget-wrap {
  border-bottom: 1px solid #52516b;
  padding-bottom: 35px;
}
.copyright-text p {
  padding-top: 15px;
  color: #fff;
  font-weight: 300;
}
.footer-bottom-link a {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}
.f-cta-title span {
  font-size: 24px;
  line-height: 1;
  color: #fff;
}
.f-cta-title h2 {
  font-size: 36px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 0;
}
.f-subscribe-form {
  position: relative;
  overflow: hidden;
}
.f-subscribe-form input {
  border: 1px solid #adadad;
  background: none;
  padding: 12px 30px;
  border-radius: 5px;
  width: 382px;
  float: left;
  margin-right: 25px;
  color: #fff;
}
.f-subscribe-form input::placeholder {
  color: #adadad;
}
.f-subscribe-form .btn {
  padding: 17px 25px;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.footer-cta-wrap {
  padding-bottom: 60px;
  border-bottom: 1px solid #484762;
}
.f-widget-text p {
  color: #fff;
  margin-bottom: 0;
}
.f-widget-social a {
  margin-right: 25px;
}
.f-widget-social a:hover i {
  color: #1eb8ff;
}
.f-widget-social a > i {
  color: #fff;
  transition: 0.3s;
}
.copyright-wrapper.boder-bt {
  border-top: 1px solid #494863;
}
.f-widget-heading h4 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 25px;
}
.f-widget-link ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}
.f-widget-link ul li::before {
  left: 0;
  top: 6px;
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  border: 1px solid #8a8989;
  border-radius: 50%;
  margin-right: 7px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
}
.f-widget-link ul li:last-child {
  margin-bottom: 0;
}
.f-widget-link ul li:hover::before {
  border-color: #ff8a73;
  background: #ff8a73;
}
.f-widget-link ul li a {
  color: #fff;
  font-size: 18px;
}
.f-widget-btn a:first-child {
  margin-right: 13px;
}
.second-f-cta .cta-icon i {
  color: #fff;
  font-size: 19px;
  margin-top: 6px;
}
.f-widget-link ul li:hover a {
  color: #ff8a73 !important;
}
.second-f-cta p {
  color: #fff;
  font-size: 18px;
}
.f-top-area {
  border-bottom: 1px solid #ced5de;
  padding-top: 170px;
}
.display-f {
  display: flex;
  align-items: center;
  border-right: 1px solid #ced5de;
  padding-top: 25px;
  padding-bottom: 60px;
}
.display-f:last-child {
  border-right: none;
}
.help-icon {
  float: left;
  display: block;
  margin-right: 30px;
}
.help-content {
  overflow: hidden;
  display: inline-block;
}
.h_more {
  position: relative;
  right: -35px;
}
.h_more a {
  font-size: 24px;
  color: #6a55a6;
}
.help-content h5 {
  font-size: 24px;
  margin-bottom: 0;
  color: #ff8a73;
  font-weight: 600;
}
.f-widget-text.f_h3 p {
  color: #242424;
}
.f-widget-social.f_h3 a i {
  color: #75787a;
}
.f-widget-social.f_h3 a:hover i {
  color: #1eb8ff;
}
.f-widget-heading.f_h3 h4 {
  color: #242424;
}
.f-widget-link.f_h3 ul li a {
  color: #242424;
}
.single-cta.second-f-cta.f_h3 p {
  color: #242424;
}
.cta-icon.f_h3 i {
  color: #242424;
}
.copyright-wrapper.boder-bt.home_3_f {
  border-top: 1px solid #ced5de;
}
.copyright-text.f_h3 p {
  color: #242424;
}
.video-icon a {
  color: #fff;
  font-size: 24px;
  position: relative;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
}
.video-icon a::before {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  animation: pulse-border 1500ms ease-out infinite;
  border-radius: 50%;
  border: 1px solid #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 1;
}
.video-icon a::after {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  animation: pulse-border 1500ms ease-out infinite;
  border-radius: 50%;
  border: 1px solid #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 1;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.video-icon a i {
  margin-left: 5px;
}
.f-video-content h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 0;
}
.f-video-content p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0;
}
.f-video-btn .btn {
  font-size: 15px;
  font-weight: 600;
  padding: 19px 36px;
}
.copyright-wrapper.border-t {
  border-top: 1px solid #595f67;
}
.home5-footer {
  background: #181b3a;
}
.home5-footer .f-top-area {
  border-bottom: none;
  padding-top: 0;
}
.f-top-bg {
  background: #fff;
  padding: 60px 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.f-widget-social.home5_f a > i {
  color: #a0a0a8;
  transition: 0.3s;
}
.f-widget-social.home5_f a:hover > i {
  color: #1eb8ff;
}

/* loader */
#loading {
  background: #f4f7ff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}
#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}
#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100px;
  transform: translate(-50%, -50%);
}

/* XL Device :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {
  .slider-one-img img {
    width: 100%;
  }
  .slider-one-content {
    padding-top: 290px;
  }
  .slider-height {
    height: 1030px;
    background-size: cover;
  }
  .succeed-img img {
    width: 100%;
  }
  .succeed-img {
    padding-top: 120px;
  }
  .testimonial-active .slick-arrow {
    left: -100px;
  }
  .testimonial-active .slick-next {
    right: -100px;
    left: auto;
  }
  .app-img img {
    width: 100%;
  }
  .slider-two-img img {
    width: 100%;
  }
  .app_task_img {
    padding-top: 100px;
  }
  .app_task_img img {
    width: 100%;
  }
  .skill-img {
    margin-left: -205px;
  }
  .services-img::before {
    width: 40%;
  }
  .slider-five-active .slick-arrow {
    left: 0;
  }
  .slider-five-active .slick-next {
    right: 0;
    left: auto;
  }
  .solutions-shape {
    left: -210px;
  }
  .speed-area-wrap {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .features-area.features-padding {
    padding-bottom: 32px;
  }
  .best-features.pb-95 {
    padding-bottom: 100px;
  }
  .our-offer.pb-90 {
    padding-bottom: 70px;
  }
  .app-padding-b {
    padding-bottom: 100px;
  }
  .pricing-area.pt-90.pb-70 {
    padding-top: 65px;
  }
  .area-bg-wrapper {
    background-size: cover;
  }
  .area-bg-wrapper.pb-200 {
    padding-bottom: 137px;
  }
  .our-offer.pt-65.pb-100 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
  .our-offer.pt-65.pb-100 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
  .pricing-area.pt-40.pb-70 {
    padding-top: 50px;
  }
  .features-area.second-f-padding {
    padding-bottom: 50px;
  }
  .succeed-area.area-bb {
    padding-top: 175px;
  }
  .second-home-skill {
    padding-top: 85px;
  }
  .services-img-wrap {
    left: -250px;
  }
  .testimonial-area.inner-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
  }
  .speed-area-wrap.inner-speed-area.pt-100.pb-100 {
    padding-top: 90px;
  }
  .app-img.animation-app {
    padding-bottom: 103px;
  }
  .slider-two-img {
    position: relative;
    right: -22px;
  }
  .task_app-area.pt-100 {
    padding-top: 92px;
  }
  .s-skill-pl {
    padding-left: 110px;
  }
  .team-bg-wrap {
    background-position: center center;
    background-size: cover;
    background: #f7f8ff;
  }
  .team-area.pt-175.pb-70 {
    padding-top: 90px;
  }
  .home-four-slider::after {
    height: 240px;
  }
  .hfour-features.pt-90 {
    padding-top: 65px;
  }
}

/* LG Device :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
  .slider-one-img img {
    width: 100%;
  }
  .slider-one-content {
    padding-top: 235px;
  }
  .slider-height {
    height: 860px;
    background-size: cover;
  }
  .home-four-slider {
    height: 1000px;
  }
  .slider-one-content span {
    font-size: 50px;
  }
  .slider-one-content h1 {
    font-size: 50px;
  }
  .slider-one-content p {
    font-size: 16px;
  }
  .features-content h3 {
    font-size: 21px;
  }
  .single-succeed {
    padding: 15px 15px;
  }
  .succeed-img img {
    width: 100%;
  }
  .succeed-img {
    padding-top: 175px;
  }
  .reports-img {
    margin-left: 0px;
  }
  .reports-img img {
    width: 100%;
  }
  .offer-img img {
    width: 100%;
  }
  .offer-padding {
    padding-left: 0;
  }
  .area-bg-wrapper {
    background-size: cover;
    background: #f4f9fd;
    padding-bottom: 0;
  }
  .app-img img {
    width: 100%;
  }
  .testimonial-active .slick-arrow {
    top: 95%;
    transform: translateY(-95%);
    left: 40px;
  }
  .testimonial-active .slick-next {
    right: 40px;
    left: auto;
  }
  .footer-widget ul li a {
    font-size: 16px;
  }
  .screenshot-active::before {
    content: none;
  }
  .single-screenshot.slick-active.slick-center img {
    box-shadow: 0px 7px 28px 2px rgba(106, 85, 166, 0.4);
  }
  .app_task_img {
    padding-top: 100px;
  }
  .app_task_img img {
    width: 100%;
  }
  .skill-img img {
    width: 100%;
  }
  .services-img::before {
    width: 40%;
  }
  .single-team-wrap {
    padding: 40px 10px;
  }
  .f-subscribe-form input {
    width: 423px;
  }
  .f-widget-btn a:first-child {
    display: none;
  }
  .d-app-img {
    margin-left: -415px;
  }
  .t-content {
    padding: 15px 30px;
  }
  .slider-four-content p {
    padding: 0px 120px;
  }
  .testimonial-wrapper {
    padding: 25px 0;
    padding-right: 20px;
    padding-left: 70px;
  }
  .slider-five-active .slick-arrow {
    left: 0;
  }
  .slider-five-active .slick-next {
    right: 0;
    left: auto;
  }
  .solutions-shape {
    left: -340px;
  }
  .sigle-solutions {
    padding: 20px 10px;
  }
  .s_services-wrap {
    padding: 40px 28px;
  }
  .f-top-bg {
    padding: 60px;
  }
  .slogan-wrap {
    padding: 40px;
  }
  .portfolio-content.portfolio-g {
    left: 0;
    right: 0;
    margin: auto;
  }
  .product-price h6 {
    font-size: 18px;
  }
  .speed-area-wrap {
    padding-top: 90px;
    padding-bottom: 70px;
  }
  .reports-area-wrap.pt-100.pb-100 {
    padding-top: 90px;
  }
  .best-features.pt-90.pb-100 {
    padding-bottom: 10px;
  }
  .our-offer.pt-65.pb-100 {
    padding-top: 55px;
    padding-bottom: 35px;
  }
  .pricing-area.pt-40.pb-70 {
    padding-top: 90px;
  }
  .screenshot-area.pt-90.pb-100 {
    padding-bottom: 50px;
  }
  .features-area.second-f-padding {
    padding-bottom: 65px;
  }
  .succeed-area.area-bb {
    padding-top: 90px;
  }
  .task_app-area.pt-100 {
    padding-top: 91px;
  }
  .second-home-skill {
    padding-top: 85px;
  }
  .testimonial-area.pt-90.pb-140 {
    padding-bottom: 120px;
  }
  .team-area.pt-180.pb-70 {
    padding-top: 160px;
  }
  .services-area.services-bg {
    margin-bottom: 0;
  }
  .services-wrapper {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .services-area.home-four-s {
    padding-bottom: 0;
  }
  .testimonial-area.home5-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
  }
  .testimonial-area.inner-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
  }
  .speed-area-wrap.inner-speed-area.pt-100.pb-100 {
    padding-top: 90px;
  }
  .projects-img.project-prnt img {
    width: 100%;
  }
  .projects-area.boder-b.project-sm-p {
    padding-bottom: 84px;
  }
  .bp-img.project-prnt img {
    width: 100%;
  }
  .best-project.project-sm-p {
    padding-bottom: 95px;
  }
  .app-img.animation-app {
    padding-bottom: 75px;
  }
  .shadow {
    bottom: 0;
    display: none;
  }
  .best-features.pb-95 {
    padding-bottom: 70px;
  }
  .our-offer.pt-65 {
    padding-top: 90px;
    padding-bottom: 70px;
  }
  .app-padding-b {
    padding-bottom: 45px;
    background: #f4f9fd;
  }
  .screenshot-area.pb-120 {
    padding-bottom: 44px;
  }
  .screenshot-title.mb-55 {
    margin-bottom: 20px;
  }
  .team-area.pt-175 {
    padding-top: 160px;
  }
  .team-area.pt-175 {
    padding-top: 160px;
    padding-bottom: 65px;
  }
  .second-skill-bg {
    width: 45%;
  }
  .second-home-team.pt-100 {
    padding-bottom: 10px;
  }
  .home-four-slider::after {
    height: 185px;
  }
  .hfour-features.pt-90 {
    padding-top: 75px;
  }
  .home-four-slider.slider-home5 {
    height: 800px;
  }
  .skill-area.pt-100.pb-10 {
    padding-bottom: 100px;
  }
  .team-area.inner-team-area.team-bg.pt-100.pb-70 {
    padding-bottom: 65px;
  }
}

/* MD Device :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .slider-one-img img {
    width: 100%;
  }
  .slider-one-content {
    padding-top: 235px;
  }
  .slider-height {
    height: 860px;
    background-size: cover;
  }
  .slider-one-content span {
    font-size: 50px;
  }
  .slider-one-content h1 {
    font-size: 50px;
  }
  .slider-one-content p {
    font-size: 16px;
  }
  .tranparent-header {
    padding: 30px 0;
    padding-bottom: 11px;
  }
  .tranparent-header.home5-transparent {
    padding-top: 0;
  }
  .reports-img {
    margin-left: 0;
    margin-bottom: 30px;
  }
  .reports-img img {
    width: 100%;
  }
  .area-bg-wrapper {
    background-size: cover;
    background: #f4f9fd;
    padding-bottom: 0;
  }
  .offer-img img {
    width: 100%;
  }
  .offer-img {
    margin-bottom: 30px;
  }
  .app-area {
    background-size: cover;
  }
  .screenshot-active::before {
    content: none;
  }
  .single-screenshot img {
    width: 100%;
  }
  .second-slider-height {
    height: 850px;
  }
  .second-succeed-img {
    text-align: center;
  }
  .succeed-area.area-bb {
    padding-bottom: 100px;
  }
  .app_task_img {
    text-align: center;
  }
  .app_task_img img {
    width: 100%;
  }
  .task-btn.mt-15 {
    margin-bottom: 30px;
  }
  .services-img::before {
    content: none;
  }
  .projects-img {
    margin-bottom: 20px;
  }
  .projects-area.boder-b {
    padding-bottom: 50px;
  }
  .main-header.header-r-p {
    padding-top: 20px;
    padding-bottom: 5px;
  }
  .s-slider-height {
    height: 700px;
  }
  .third-features-icon::after {
    content: none;
  }
  .bp-content {
    margin-bottom: 30px;
  }
  .wp-inner {
    border: none !important;
  }
  .work-process
    .row
    .col-12:nth-child(2)
    > .work-process-wrapper
    .row
    .col-lg-6:first-child {
    order: 0;
  }
  .download-area.download-bg {
    padding-top: 90px;
    padding-bottom: 100px;
  }
  .second-pricing-box {
    margin-bottom: 50px;
  }
  .home-four-slider {
    height: 850px;
  }
  .home-four-slider::after {
    height: 155px;
  }
  .slider-four-content p {
    padding: 0;
  }
  .col-lg-3.md-none {
    display: none;
  }
  .wcontact-btn {
    margin-bottom: 30px;
  }
  .solutions-shape {
    display: none;
  }
  .f-top-bg {
    padding: 30px 20px;
  }
  .home5-display-f {
    padding-bottom: 20px;
  }
  .help-content.home5-hc h5 {
    font-size: 20px;
  }
  .section-title.second-title p {
    padding: 0;
  }
  .portfolio-inner:hover .portfolio-content.portfolio-g {
    bottom: 20px;
  }
  .portfolio-content.portfolio-g {
    left: 0;
    right: 0;
    margin: auto;
  }
  .s-portfolio-content {
    margin-bottom: 30px;
  }
  .blog-btn.inner-blog-btn.mt-20.text-center {
    margin-bottom: 0;
  }
  .post-comment-form {
    margin-bottom: 30px;
  }
  .post-comment-form.sm-margin {
    margin-bottom: 0;
  }
  .contact-title {
    margin-bottom: 25px;
  }
  .cta-padding-r {
    padding-right: 15px;
  }
  .cta-padding-l {
    padding-left: 15px;
  }
  .paddingX {
    padding-right: 15px;
    padding-left: 15px;
  }
  .contact-map {
    display: none;
  }
  .contact-form button {
    margin-bottom: 0;
  }
  .contact-cta {
    margin-bottom: 30px;
  }
  .speed-area-wrap {
    padding-top: 90px;
    padding-bottom: 70px;
  }
  .features-area.features-padding {
    padding-bottom: 40px;
  }
  .best-features.pt-90.pb-100 {
    padding-bottom: 25px;
  }
  .our-offer.pt-65.pb-100 {
    padding-top: 25px;
    padding-bottom: 0;
  }
  .single-offer:last-child {
    margin-bottom: 0;
  }
  .pricing-area.pt-40.pb-70 {
    padding-top: 0;
  }
  .screenshot-area.pt-90.pb-100 {
    padding-bottom: 45px;
  }
  .features-area.second-f-padding {
    padding-bottom: 65px;
    padding-top: 60px;
  }
  .succeed-area.area-bb {
    padding-top: 90px;
  }
  .second-home-skill {
    padding-top: 90px;
    padding-bottom: 41px;
  }
  .testimonial-area.pt-90.pb-140 {
    padding-bottom: 95px;
  }
  .team-area.pt-180.pb-70 {
    padding-top: 179px;
  }
  .services-wrapper {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .work-process.pt-100.pb-100 {
    padding-bottom: 70px;
  }
  .pricing-area.border-b.pt-90.pb-120 {
    padding-bottom: 72px;
  }
  .brand-area.border-b.pt-70.pb-100 {
    padding-bottom: 100px;
  }
  .services-area.services-bg.pt-90.mb-40 {
    margin-bottom: 0;
  }
  .services-area.home-four-s {
    padding-bottom: 0;
  }
  .testimonial-area.home5-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
  }
  .testimonial-area.inner-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
    padding-top: 20px;
  }
  .skill-area.inner-skill-area {
    padding-top: 90px;
    padding-bottom: 10px;
  }
  .brand-area.border-b.inner-brand.pt-70.pb-100 {
    padding-top: 40px;
  }
  .speed-area-wrap.inner-speed-area.pt-100.pb-100 {
    padding-top: 90px;
  }
  .second-pricing-plan.inner-pricing-plan {
    padding-bottom: 75px;
  }
  .projects-img.project-prnt {
    text-align: center;
    margin-bottom: 68px;
  }
  .bp-img.project-prnt {
    text-align: center;
  }
  .app-img.animation-app {
    text-align: center;
  }
  .app-img .watch img,
  .app-img .shadow img {
    width: unset;
  }
  .best-features.pb-95 {
    padding-bottom: 70px;
  }
  .our-offer.pt-65 {
    padding-top: 100px;
    padding-bottom: 95px;
  }
  .app-freatures-wrapper.pt-65 {
    padding-top: 0;
  }
  .app-img.animation-app {
    padding-bottom: 0;
  }
  .app-padding-b {
    padding-bottom: 90px;
    background: #f4f9fd;
    padding-top: 90px;
  }
  .shadow {
    display: none;
  }
  .screenshot-title.mb-55 {
    margin-bottom: 20px;
  }
  .screenshot-area.pb-120 {
    padding-bottom: 45px;
  }
  .footer-bg {
    padding-top: 100px;
  }
  .task_app-area.pt-100 {
    padding-top: 92px;
  }
  .third-testimonial-area.pt-65 {
    padding-top: 60px;
  }
  .f-top-area {
    padding-top: 100px;
  }
  .third-footer {
    padding-top: 0;
    background: #f3f9fd;
  }
  .hfour-features.pt-90 {
    padding-top: 75px;
  }
  .home-four-slider.slider-home5 {
    height: 700px;
  }
  .slider-h5-padding {
    padding-top: 300px;
  }
  .skill-app {
    text-align: center;
  }
  .skill-app img {
    width: unset;
  }
  .skill-area.pt-100.pb-10 {
    padding-bottom: 100px;
  }
  .counter-area.counter-bg.pt-95.pb-65 {
    padding-bottom: 60px;
  }
  .team-area.inner-team-area.team-bg.pt-100 {
    padding-top: 90px;
  }
  .pricing-area.inner-team-area.pt-100 {
    padding-top: 90px;
  }
  .contact-area.pt-100.pb-100 {
    padding-top: 90px;
  }
  .contact-map-wrap.mt-100 {
    margin-top: 65px;
  }
}

/* Extra small Device. */
@media (max-width: 767px) {
  .tranparent-header {
    padding: 30px 0;
    padding-bottom: 11px;
  }
  .screenshot-active::before {
    content: none;
  }
  .slider-height {
    height: 800px;
    background-size: cover;
  }
  .slider-one-content {
    padding-top: 170px;
  }
  .section-title.center-title p {
    padding: 0;
  }
  .single-features {
    margin-bottom: 60px;
  }
  .area-bg-wrapper {
    background-size: cover;
    background: #f4f9fd;
    padding-bottom: 0;
  }
  .reports-img {
    margin-left: 0;
    margin-bottom: 30px;
  }
  .reports-img img {
    width: 100%;
  }
  .offer-img img {
    width: 100%;
  }
  .offer-img {
    margin-bottom: 30px;
  }
  .app-area {
    background-size: cover;
  }
  .slider-one-content span {
    font-size: 45px;
  }
  .slider-one-content h1 {
    font-size: 52px;
  }
  .slider-btn .btn:first-child {
    margin-bottom: 10px;
  }
  .slider-btn {
    z-index: 999;
    position: relative;
  }
  .section-title h1 {
    font-size: 32px;
  }
  .get-start-btn .btn:first-child {
    margin-bottom: 10px;
  }
  .single-screenshot img {
    width: 100%;
  }
  .footer-widget {
    padding-top: 0px;
  }
  .footer-widget.white-bg {
    padding-top: 70px;
  }
  .second-succeed-img {
    text-align: center;
  }
  .succeed-area.area-bb {
    padding-bottom: 100px;
  }
  .services-img::before {
    content: none;
  }
  .projects-img {
    margin-bottom: 20px;
  }
  .projects-area.boder-b {
    padding-bottom: 50px;
  }
  .slider-one-content.second-slider-content span {
    font-size: 24px;
  }
  .slider-one-content.second-slider-content h1 {
    padding-right: 0;
    font-size: 30px;
  }
  .second-slider-content p {
    padding-right: 0;
  }
  .second-slider-height {
    height: 650px;
  }
  .copyright-text {
    text-align: center;
  }
  .succeed-title h1 {
    padding-right: 0;
  }
  .succeed-btn .btn:first-child {
    margin-bottom: 10px;
  }
  .task-title p {
    padding-right: 0;
  }
  .task-btn .btn:first-child {
    margin-bottom: 10px;
  }
  .testimonial-title p {
    padding: 0;
  }
  .testimonial-wrapper {
    padding: 25px 0;
    padding-right: 21px;
    padding-left: 55px;
  }
  .f-subscribe-form {
    overflow: unset;
  }
  .f-subscribe-form form {
    overflow: hidden;
  }
  .f-subscribe-form input {
    width: 100%;
    margin-right: 0;
  }
  .f-subscribe-form .btn {
    display: block;
    top: unset;
    transform: unset;
    bottom: -60px;
    left: 0;
    right: unset;
  }
  .footer-cta-wrap {
    padding-bottom: 120px;
    padding-top: 95px;
  }
  .main-header.header-r-p {
    padding-top: 20px;
    padding-bottom: 5px;
  }
  .s-slider-height {
    height: 600px;
  }
  .third-features-icon::after {
    content: none;
  }
  .bp-content {
    margin-bottom: 30px;
  }
  .blog-btn.inner-blog-btn.mt-20.text-center {
    margin-bottom: 0;
  }
  .wp-inner {
    border: none !important;
  }
  .work-process
    .row
    .col-12:nth-child(2)
    > .work-process-wrapper
    .row
    .col-lg-6:first-child {
    order: 0;
  }
  .download-area.download-bg {
    padding-top: 90px;
    padding-bottom: 100px;
  }
  .second-pricing-box {
    margin-bottom: 50px;
  }
  .team-btn {
    margin-bottom: 30px;
  }
  .display-f {
    border-right: none;
    padding-top: 15px;
    padding-bottom: 30px;
  }
  .slider-three-content span {
    font-size: 32px;
  }
  .slider-three-content h1 {
    font-size: 40px;
  }
  .home-three-t p {
    padding: 0;
  }
  .wp-img img {
    width: 100%;
  }
  .col-lg-6.pl-0 {
    padding-left: 15px !important;
  }
  .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .wp-content {
    padding-left: 0;
  }
  .section-title span {
    font-size: 27px;
    line-height: 1.6;
  }
  .t-content {
    padding: 9px 20px;
  }
  .subs-form input {
    padding: 20px 20px;
  }
  .subs-form .btn {
    padding: 17px 33px;
    font-size: 15px;
  }
  .subs-form input::placeholder {
    font-size: 15px;
  }
  .help-content h5 {
    font-size: 18px;
  }
  .home-four-slider {
    height: 800px;
  }
  .slider-four-content p {
    padding: 0;
  }
  .wcontact-btn {
    margin-bottom: 30px;
  }
  .f-video-content h1 {
    font-size: 44px;
  }
  .solutions-shape {
    display: none;
  }
  .tranparent-header.home5-transparent {
    padding-top: 0;
  }
  .slider-h5-padding {
    padding-top: 250px;
  }
  .home-four-slider.slider-home5 {
    height: 700px;
  }
  .sigle-solutions {
    padding: 30px;
  }
  .f-top-bg {
    padding: 20px;
  }
  .section-title.second-title p {
    padding: 0;
  }
  .breadcrumb-area {
    height: 300px;
  }
  .breadcrumb-wrap {
    padding-top: 150px;
  }
  .portfolio-inner:hover .portfolio-content.portfolio-g {
    bottom: 20px;
  }
  .portfolio-content.portfolio-g {
    left: 0;
    right: 0;
    margin: auto;
    width: 255px;
  }
  .s-portfolio-content {
    margin-bottom: 30px;
  }
  .product-price h6 {
    font-size: 18px;
  }
  .blog-btn.mt-20.text-center {
    margin-bottom: 50px;
  }
  .post-comment-form {
    margin-bottom: 30px;
  }
  .post-comment-form.sm-margin {
    margin-bottom: 0;
  }
  .author-comment-box {
    position: relative;
  }
  .author-comments-avatar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    float: unset;
    display: unset;
  }
  .author-comments-text {
    padding: 23px;
    padding-left: 145px;
  }
  .latest-comments ul li:nth-child(2) {
    padding-left: 0px;
  }
  blockquote.blockquote i {
    left: 30px;
  }
  blockquote.blockquote {
    padding: 30px 35px;
    font-size: 20px;
    padding-left: 120px;
  }
  .blog-details-content h1 {
    font-size: 28px;
  }
  .post-meta-info.text-right {
    text-align: left !important;
    margin-top: 10px;
  }
  .contact-title {
    margin-bottom: 25px;
  }
  .cta-padding-r {
    padding-right: 15px;
  }
  .cta-padding-l {
    padding-left: 15px;
  }
  .paddingX {
    padding-right: 15px;
    padding-left: 15px;
  }
  .contact-map {
    display: none;
  }
  .contact-form button {
    margin-bottom: 0;
  }
  .contact-cta {
    margin-bottom: 30px;
  }
  .screenshot-area.pt-90.pb-100 {
    padding-bottom: 45px;
  }
  .speed-area-wrap.pt-100.pb-100 {
    padding-top: 90px;
    padding-bottom: 70px;
  }
  .best-features.pt-90.pb-100 {
    padding-bottom: 25px;
  }
  .our-offer.pt-65.pb-100 {
    padding-top: 0;
    padding-bottom: 30px;
  }
  .features-area.second-f-padding {
    padding-bottom: 65px;
    padding-top: 70px;
  }
  .succeed-area.area-bb {
    padding-top: 95px;
  }
  .second-home-skill {
    padding-top: 90px;
    padding-bottom: 42px;
  }
  .testimonial-area.pt-90.pb-140 {
    padding-bottom: 140px;
  }
  .team-bg-wrap {
    background-position: center center;
    background-size: cover;
    background: #f7f8ff;
  }
  .team-area.pt-175.pb-70 {
    padding-top: 93px;
  }
  .services-wrapper {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .work-process.pt-100.pb-100 {
    padding-bottom: 70px;
  }
  .pricing-area.border-b.pt-90.pb-120 {
    padding-bottom: 72px;
  }
  .brand-area.border-b.pt-70.pb-100 {
    padding-bottom: 100px;
  }
  .services-area.services-bg.pt-90.mb-40 {
    margin-bottom: 0;
  }
  .team-area.second-home-team {
    padding-top: 90px;
  }
  .f-top-area {
    padding-top: 85px;
  }
  .services-area.home-four-s {
    padding-bottom: 0;
  }
  .testimonial-area.home5-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
  }
  .brand-area.home4-brand.border-b.pt-70.pb-100 {
    padding-bottom: 100px;
  }
  .h_more {
    right: -10px;
  }
  .testimonial-area.inner-testimonial.pt-90.pb-140 {
    padding-bottom: 140px;
    padding-top: 20px;
  }
  .skill-area.inner-skill-area {
    padding-top: 90px;
    padding-bottom: 10px;
  }
  .brand-area.border-b.inner-brand.pt-70.pb-100 {
    padding-top: 70px;
    padding-bottom: 100px;
  }
  .speed-area-wrap.inner-speed-area.pt-100.pb-100 {
    padding-top: 90px;
  }
  .second-pricing-plan.inner-pricing-plan {
    padding-bottom: 75px;
  }
  .sidebar-instagram ul li:last-child {
    margin-bottom: 0;
  }
  .projects-img.project-prnt {
    margin-bottom: 48px;
  }
  .projects-img.project-prnt img {
    width: 100%;
  }
  .bp-img.project-prnt img {
    width: 100%;
  }
  .best-project.project-sm-p {
    padding-bottom: 125px;
  }
  .best-features.pb-95 {
    padding-bottom: 70px;
  }
  .our-offer.pt-65 {
    padding-top: 100px;
    padding-bottom: 70px;
  }
  .app-padding-b {
    padding-bottom: 55px;
    background: #f4f9fd;
    padding-top: 90px;
  }
  .app-freatures-wrapper.pt-65 {
    padding-top: 0;
  }
  .screenshot-title.mb-55 {
    margin-bottom: 20px;
  }
  .screenshot-area.pt-90.pb-120 {
    padding-bottom: 43px;
  }
  .footer-bg {
    padding-top: 100px;
  }
  .slider-shape-wrap {
    display: none;
  }
  .features-area.features-padding.pb-40 {
    padding-top: 90px;
  }
  .best-s-pl {
    padding-left: 15px;
  }
  .footer-bg.s-footer {
    padding-top: 0;
    background: #343351;
  }
  .third-testimonial-area.pt-65 {
    padding-top: 60px;
  }
  .f-top-area {
    padding-top: 85px;
  }
  .third-footer {
    padding-top: 0;
    background: #f3f9fd;
  }
  .home-four-slider::after {
    display: none;
  }
  .home-four-slider .slider-wrapper {
    padding-top: 200px;
  }
  .slider-home5 .slider-wrapper {
    padding-top: 0;
  }
  .skill-area.pt-100.pb-10 {
    padding-bottom: 100px;
  }
  .counter-area.counter-bg.pt-95.pb-65 {
    padding-bottom: 60px;
  }
  .team-area.inner-team-area.team-bg.pt-100 {
    padding-top: 90px;
  }
  .pricing-area.inner-team-area.pt-100 {
    padding-top: 90px;
  }
  .contact-area.pt-100.pb-100 {
    padding-top: 90px;
  }
  .contact-map-wrap.mt-100 {
    margin-top: 65px;
  }
  .b-content-box {
    padding: 25px 20px;
  }
}

/* SM Small Device :550px. */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bg-text h2 {
    font-size: 90px;
  }
  .slider-one-content h1 {
    font-size: 60px;
  }
  .slider-one-content span {
    font-size: 60px;
  }
  .slider-btn .btn:first-child {
    margin-bottom: 0px;
  }
  .section-title h1 {
    font-size: 36px;
  }
  .get-start-btn .btn:first-child {
    margin-bottom: 0px;
  }
  .slider-one-content.second-slider-content span {
    font-size: 39px;
  }
  .slider-one-content.second-slider-content h1 {
    padding-right: 0;
    font-size: 52px;
  }
  .second-slider-content p {
    padding-right: 90px;
  }
  .succeed-title h1 {
    padding-right: 60px;
  }
  .succeed-btn .btn:first-child {
    margin-bottom: 0px;
  }
  .task-title p {
    padding-right: 80px;
  }
  .task-btn .btn:first-child {
    margin-bottom: 0px;
  }
  .testimonial-wrapper {
    padding: 50px 0;
    padding-right: 30px;
    padding-left: 70px;
  }
  .f-subscribe-form input {
    width: 320px;
    margin-right: 25px;
  }
  .f-subscribe-form .btn {
    display: block;
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
    left: unset;
    right: 0;
  }
  .footer-cta-wrap {
    padding-bottom: 60px;
  }
  .slider-three-content span {
    font-size: 45px;
  }
  .slider-three-content h1 {
    font-size: 60px;
  }
  .wp-img img {
    width: unset;
  }
  .t-content {
    padding: 25px 40px;
  }
  .subs-form input {
    padding: 25px 40px;
  }
  .subs-form .btn {
    padding: 18px 55px;
    font-size: 24px;
  }
  .help-content h5 {
    font-size: 24px;
  }
  .f-top-bg {
    padding: 20px 60px;
  }
  .pricing-box {
    background-position: center;
  }
  .portfolio-inner:hover .portfolio-content.portfolio-g {
    bottom: 30px;
  }
  .portfolio-content.portfolio-g {
    left: 30px;
    width: 285px;
    right: unset;
    margin: unset;
  }
  .product-price h6 {
    font-size: 24px;
  }
  blockquote.blockquote i {
    left: 30px;
  }
  blockquote.blockquote {
    padding: 30px 35px;
    font-size: 20px;
    padding-left: 120px;
  }
  .latest-comments ul li:nth-child(2) {
    padding-left: 80px;
  }
  .post-meta-info.text-right {
    text-align: right !important;
    margin-top: 0px;
  }
  .f-top-area {
    padding-top: 85px;
  }
  .h_more {
    right: -35px;
  }
  .home-four-slider .slider-wrapper {
    padding-top: 250px;
  }
  .slider-home5 .slider-wrapper {
    padding-top: 0;
  }
  .skill-area.inner-skill-area {
    padding-bottom: 5px;
  }
  .second-home-skill {
    padding-bottom: 36px;
  }
}
</style>
